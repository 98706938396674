import SlideShowContainerComponent from '@wix/thunderbolt-elements/src/components/SlideShowContainer/viewer/SlideShowContainer';
import SlideShowContainerController from '@wix/thunderbolt-elements/src/components/SlideShowContainer/viewer/SlideShowContainer.controller';


const SlideShowContainer = {
  component: SlideShowContainerComponent,
  controller: SlideShowContainerController
};


export const components = {
  ['SlideShowContainer']: SlideShowContainer
};


// temporary export
export const version = "1.0.0"
